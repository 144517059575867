import { debounce } from 'lodash';
import type { ColorResult } from 'react-color';
import { SketchPicker } from 'react-color';
import {
  Button,
  DialogTrigger,
  Popover,
  Tooltip,
  RangeSlider,
} from 'opencosmos-ui';
import { useLocalisation } from 'utils/hooks/useLocalisation';

interface IProps {
  /**
   * In the HEX RRGGBBAA format, including the alpha code
   */
  color: string;
  disabled: boolean;
  /**
   * Color is in the HEX RRGGBBAA format, including the alpha code
   */
  onChange: (newColor: string) => void;
  fillOpacity?: number;
  onFillOpacityChange?: (newFillOpacity: number) => void;
}

/**
 * ColorControl renders a color button that, when clicked, displays a color
 * selection dialog. The onChange handler is debounced to improve performance.
 *
 * The color values use the HEX RRGGBBAA format, which includes an alpha code
 * at the end.
 */
const ColorControl = ({
  color,
  disabled,
  onChange,
  fillOpacity,
  onFillOpacityChange,
}: IProps) => {
  const debouncedOnChange = debounce((value: ColorResult): void => {
    const colorCode = value.hex;
    onChange(colorCode);
  }, 200);
  const { translate } = useLocalisation();

  return (
    <DialogTrigger>
      <Tooltip
        content={translate('datacosmos.layers.colourPicker')}
        placement="top"
      >
        <Button
          iconOnly
          style={{ backgroundColor: disabled ? '#8c8c8c' : color }}
          className="h-5 w-5 rounded-full"
          isDisabled={disabled}
        />
      </Tooltip>
      <Popover placement="bottom left" className="bg-surface shadow-lg">
        <>
          <SketchPicker
            className="bg-surface"
            styles={{
              default: {
                picker: {
                  boxShadow: 'none',
                },
              },
            }}
            color={color}
            onChangeComplete={(value) => {
              debouncedOnChange(value);
            }}
            disableAlpha
          />
          {fillOpacity !== undefined && onFillOpacityChange && (
            <div className="m-1">
              <RangeSlider
                label={{ text: translate('datacosmos.layers.fillOpacity') }}
                numberOfHandles={1}
                value={[fillOpacity]}
                onChangeEnd={(value) => {
                  onFillOpacityChange(
                    typeof value === 'number' ? value : value[0]
                  );
                }}
                minValue={0}
                maxValue={1}
                step={0.1}
                showScale={false}
                showValuesAboveHandles
              />
            </div>
          )}
        </>
      </Popover>
    </DialogTrigger>
  );
};

export default ColorControl;
